import { ChildrenProps } from '@/models/utils/ChildrenProps';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexBox } from '../shared/FlexBox';

const StyledFlexBox = styled(FlexBox)`
  height: 4rem;
  margin: 1rem 0;
`;

const StyledHeader = styled.h1`
  font-size: 28px;
  font-weight: bold;
  white-space: nowrap;
  margin: 0;
`;

const Header = ({ children }: ChildrenProps) => (
  <StyledHeader>
    {children}
  </StyledHeader>
);

const Breadcrumb = styled.h2`
  color: ${props => props.theme.marineBlue50};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface PageHeaderProps {
  header: React.ReactNode,
  breadcrumb?: {
    label: string;
    to?: string;
    onClick?: () => void;
  },
}
export const PageHeader = ({ header, breadcrumb }: PageHeaderProps) => {
  const BreadcrumbWrapper = ({ children }: ChildrenProps) =>
    breadcrumb?.to ? <Link to={breadcrumb?.to}>{children}</Link> : <>{children}</>;

  return (
    <StyledFlexBox justifyContent="center" flexDirection="column">
      {!!breadcrumb && (
        <BreadcrumbWrapper>
          <Breadcrumb onClick={breadcrumb?.onClick}>
            {'< '}
            {breadcrumb.label}
          </Breadcrumb>
        </BreadcrumbWrapper>
      )}
      <Header>{header}</Header>
    </StyledFlexBox>
  );
};
