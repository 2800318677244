import { isAdmin, isUnderwriter } from '@/components/routing/roles';
import { Chevron } from '@/components/shared/Chevron';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { useDefaultTermsConditions } from '@/services/termsService';
import { unique } from '@/utility/arrayHelpers';
import { WarWeb } from '@/war';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { AgreementEntry, useAgreementsOverviewContext } from '../agreementsOverview/AgreementsOverviewContext';
import { useFilterableTableHeadersContext } from './FilterableTableHeadersContext';
import { renderSharedDefaultTermsTableCells } from './SharedTableCells';
import { TermsConditions } from './TermsConditions';
import { CountrySelection, useTermsConditionsPageContext } from './TermsConditionsPageContext';

const StyledFleetLink = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  & svg {
    transform: rotate(180deg);
  }
`;

const AreaName = styled.div<{ type?: string }>`
  ${props => props.type === 'Ocean' && css`
     font-style: italic;
  `};
  cursor: pointer;
`;

interface AreaRowProps {
  terms: WarWeb.DefaultTcGroup;
  expanded: boolean;
  toggleExpand: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void;
  focusedId?: string;
  setFocusedId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const AreaRow = ({ terms, expanded, toggleExpand, focusedId, setFocusedId }: AreaRowProps) => {
  const { agreementEntries, setAgreementEntries } = useAgreementsOverviewContext();
  const { editAction } = useTermsConditionsPageContext();
  const { filters } = useFilterableTableHeadersContext();
  const params = { ...filters, selectedLevel1: [terms.level1], selectedLevel2: [terms.level2] };

  const [isRowChecked, setIsRowChecked] = React.useState(false);

  const { data: agreementsOnArea } = useDefaultTermsConditions(isRowChecked && !filters?.selectedCoveredAreaIds ? params : undefined, JSON.stringify(params));
  const agreementsOnAreaFiltered: WarWeb.DefaultTcMatch[] = unique(agreementsOnArea?.items || [], ['coveredAreaIds', 'vesselTypeIds']);

  const handleInputChange = () => {
    setIsRowChecked(!isRowChecked);
  };

  React.useEffect(() => {
    if (filters?.selectedCoveredAreaIds) return;
    const entriesExceptCurrent = agreementEntries.filter(x => !(x.level1 === terms.level1 && x.level2 === terms.level2));

    if (!isRowChecked) {
      setAgreementEntries(entriesExceptCurrent);
      return;
    }
    if (!agreementsOnAreaFiltered) return;

    const entries: AgreementEntry[] = [...entriesExceptCurrent, ...agreementsOnAreaFiltered
      .filter(x => !x.multipleSets) // Skip rows with multiple sets
      .map(x => ({
        agreementId: x.agreementId,
        agreementIds: x.agreementIds,
        areaIds: x.coveredAreaIds,
        vesselTypeIds: x.vesselTypeIds,
        level1: x.level1,
        level2: x.level2,
        type: x.coveredAreaType,
        daysCovered: x.breachDays
      }))];
    setAgreementEntries(entries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRowChecked, agreementsOnArea]);

  const agreementsForCurrentRow = agreementEntries.filter(x => x.level1 === terms.level1 && x.level2 === terms.level2);

  const hasSomeSelected = agreementsForCurrentRow.length > 0;
  const isAllSelected = hasSomeSelected && (agreementsForCurrentRow.length === agreementsOnAreaFiltered.length
    || agreementsForCurrentRow.length === agreementsOnArea?.items.length);

  return (
    <TableRow hasFocus={focusedId === `${terms.level1}.${terms.level2}`} onHover={() => setFocusedId(undefined)} hover>
      {isAdmin() && (
        <TableCell slim onClick={handleInputChange}>
          <Checkbox
            readOnly
            name={`${terms.level1}.${terms.level2}`}
            checked={hasSomeSelected}
            indeterminate={hasSomeSelected && !isAllSelected}
            onKeyDown={e => e.key === 'Enter' ? editAction() : toggleExpand(e)}
            onFocus={() => setFocusedId(`${terms.level1}.${terms.level2}`)}
            onBlur={() => setFocusedId(undefined)}
          />
        </TableCell>
      )}
      <TableCell slim hover>
        <StyledFleetLink tabIndex={isUnderwriter() ? 0 : undefined} onClick={toggleExpand} onKeyDown={toggleExpand}>
          {expanded ? <Chevron top thin /> : <Chevron bottom thin />}
        </StyledFleetLink>
      </TableCell>
      <TableCell>
        <AreaName type={terms.coveredAreaType} onClick={toggleExpand}>
          {terms.level2}
        </AreaName>
      </TableCell>
      {renderSharedDefaultTermsTableCells(terms)}
      {expanded && <TermsConditions level1={terms.level1} level2={terms.level2} />}
    </TableRow>
  );
};

interface AreaProps {
  terms: WarWeb.DefaultTcGroup;
  expanded: CountrySelection[];
  toggleCountry: (level1: string, level2: string) => void;
  focusedId?: string;
  setFocusedId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
export const Area = ({ terms, expanded, toggleCountry, focusedId, setFocusedId }: AreaProps) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(expanded.some(a => a.level1 === terms.level1 && a.level2 === terms.level2));

  const toggle = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, level1: string, level2: string) => {
    if (e.type === 'keydown') {
      const keyboardEvent = e as React.KeyboardEvent<HTMLDivElement>;
      if (keyboardEvent.key !== 'ArrowRight' && keyboardEvent.key !== 'ArrowLeft') return;
      if ((keyboardEvent.key === 'ArrowRight' && isExpanded) || (keyboardEvent.key === 'ArrowLeft' && !isExpanded)) return;
      e.preventDefault();
    }
    toggleCountry(level1, level2);
  };

  React.useEffect(() => {
    setIsExpanded(expanded.some(a => a.level1 === terms.level1 && a.level2 === terms.level2));
  }, [expanded]);

  return (
    <AreaRow
      terms={terms}
      expanded={isExpanded}
      toggleExpand={(e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => toggle(e, terms.level1, terms.level2)}
      focusedId={focusedId}
      setFocusedId={setFocusedId}
    />
  );
};
