import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { MultipleValues } from '@/components/pages/defaultTermsDetails/shared/MultipleValues';
import { isInternalUser } from '@/components/routing/roles';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import {
  SpanCell, TableCell, TableRow
} from '@/components/shared/Table/core/Components';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { formatPercentage } from '@/utility/formatter';
import { MaxNumberTimeValue } from '@/utility/globals';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { NumberField } from '../../Form/fields/NumberField';
import { checkLink, HateoasHandler } from '../../HateoasHandler';
import { Validator } from '../../Validator';

export const ItemHeader = styled.span`
  display: flex;
  flex-direction: row;

  padding-bottom: 3px;
  font-weight: 600;
  font-size: 16px;
`;

export const ItemSubheader = styled.span`
  font-size: 16px;
  word-break: break-word;
`;

const TableCellSlim = styled(TableCell)`
  padding: 0px;
  padding-left: 10px;
`;

export interface RebateAreaOption {
  rebateId: number;
  coveredAreaId: any;
  type: string;
  name: string;
  hra?: string;
  included: boolean;
}
interface RebateTableRowProps {
  rebate: WarWeb.Rebate | WarWeb.RebateSummary;
  rebateAreaOptions: RebateAreaOption[];
}
export const RebateTableRow = ({ rebate, rebateAreaOptions }: RebateTableRowProps) => {
  const { errors } = useValidationContext();
  const { hateoasExecutor, loading, lastRequest } = useHateoasExecutorContext();

  const { selectedConfiguration, isNoClaimsBonusBased } = useQuoteContext();

  const [rebateState, setRebateState] = useState<DirtyableValue<string>>({ value: rebate?.value.toString() });

  const resetState = () => {
    setRebateState({ value: rebate?.value.toString(), isDirty: false });
  };

  useEffect(() => {
    resetState();
  }, [selectedConfiguration]);

  useEffect(() => {
    const isRelevant = !!checkLink(rebate.links, lastRequest);
    if (errors && isRelevant) {
      resetState();
    }
  }, [errors]);

  // TODO: Should be filtered in backend: https://instech.atlassian.net/browse/INSIWW-2670
  if (!(isNoClaimsBonusBased || (!isNoClaimsBonusBased && rebate.shortName !== 'NCB') || (isInternalUser() && rebate.shortName !== 'CP'))) {
    return null;
  }

  const commitRebateSelection = () => {
    const updateRebatesRequest = {
      rebateId: rebate.id,
      isSelected: !rebate.isSelected
    };
    void hateoasExecutor(rebate.links, QuoteActions.SelectRebate, updateRebatesRequest, 'rebates');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRebateState({ value, isDirty: true });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const commitRebate = () => {
    if (rebateState.isDirty) {
      const setRebateRequest = {
        rebateId: rebate.id,
        value: rebateState.value ?? null
      };
      void hateoasExecutor(rebate.links, QuoteActions.SetRebate, setRebateRequest, 'rebates');
    }
    setRebateState({ ...rebateState, isDirty: false });
  };

  const labels = ['Selected', 'Not Selected'];

  const renderAreaRebate = (o: RebateAreaOption) => (
    <div key={o.coveredAreaId}>
      <strong>{o.name}</strong>
      &nbsp;-&nbsp;
      {o.included ? labels[0] : labels[1]}
    </div>
  );

  return (
    <>
      <TableRow shaded={false} key={rebate.id}>
        {(isNoClaimsBonusBased || rebate.shortName !== 'CP')
          ? (
            <TableCellSlim>
              <HateoasHandler
                links={rebate.links}
                action={QuoteActions.SelectRebate}
                editVariant={(
                  <MultipleValues options={rebateAreaOptions.filter(r => r.rebateId === rebate.id)} render={renderAreaRebate}>
                    <Checkbox
                      checked={rebate.isSelected}
                      onChange={commitRebateSelection}
                      disabled={loading}
                      name={`selected-${rebate.name}-${rebate.id}`}
                      indeterminate={rebate.isSelected === null} />
                  </MultipleValues>
                )}
                viewVariant={(
                  <Checkbox
                    name={`selected-${rebate.name}-${rebate.id}`}
                    backgroundColor={theme.border.gray}
                    checked={rebate.isSelected}
                    disabled
                  />
                )}
              />
            </TableCellSlim>
          )
          :
          <TableCellSlim />}
        <TableCell textWrap onClick={commitRebateSelection}>
          <ItemHeader>{rebate.shortName}</ItemHeader>
          <ItemSubheader>{rebate.name}</ItemSubheader>
        </TableCell>
        <TableCell>
          <HateoasHandler
            links={rebate.links}
            action={QuoteActions.SetRebate}
            editVariant={(
              <NumberField
                maxValue={MaxNumberTimeValue}
                step={0.01}
                disabled={loading}
                name="rebate"
                noErrors
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onBlur={commitRebate}
                value={rebateState.value != null ? rebateState.value : ''}
                postfix="%"
                dirty={rebateState.isDirty}
              />
            )}
            viewVariant={formatPercentage(rebate.value)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <SpanCell height="1" start={1} end={-1}>
          <Validator keys={['Value']} isRelevant={!!checkLink(rebate?.links, lastRequest)} />
        </SpanCell>
      </TableRow>
    </>
  );
};
