import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { ErrorMessage } from '@/components/shared/Form/core/Components';
import { TextField } from '@/components/shared/Form/FormFields';
import {
  NoDataRow, renderTableHeaders, Table, TableRow
} from '@/components/shared/Table/Table';
import {
  AgreementTypeEnum,
  ContractType,
  ItemSummary,
  RateItemSummary,
  TextConditionSummary,
  ValueSummary,
} from '@/models/Agreement';
import { usePorts } from '@/services/portsService';
import { useVesselTypes } from '@/services/taxonomyService';
import { useVessels } from '@/services/vesselsService';
import { getInputValue } from '@/utility/formatter';
import { theme } from '@/utility/theme';
import { TableCell } from '@instech/components';
import { Check } from '@instech/icons';
import React, {
  useCallback, useMemo, useState
} from 'react';
import styled, { css } from 'styled-components';
import { targetTypeToFriendlyString } from '@/models/TargetType';
import { Box } from '../../shared/Box';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementDataContext } from './AgreementDataContext';
import { AgreementViewMode, useAgreementDetailsContext } from './AgreementDetailsContext';
import { useAgreementSelectionContext } from './AgreementSelectionContext';
import { WarnOverwriteExistingAgreements } from './WarnOverwriteExistingAgreements';
import { useAgreementTargets } from '../agreements/useAgreementTargets';

const Content = styled.div`
  border: 2px solid ${theme.lightBlue};
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
`;
const Column = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props =>
    props.gap &&
    css`
      row-gap: 1rem;
    `};
`;
const Target = styled.div`
  padding: 1rem 1rem;
  background-color: ${theme.lightBlue};
  border-radius: 3px;
  font-size: 14px;
`;

const TargetHeader = styled.h3<{ error?: boolean }>`
  font-size: 16px;
  font-weight: bold;
  margin: 0;

  &:not(:first-child) {
    padding-top: 1em;
  }

  ${props =>
    props.error &&
    css`
      color: ${theme.mediumRed};
    `};
`;

const StyledError = styled(ErrorMessage)`
  justify-content: end;
`;

const Changes = styled.div`
  height: 300px;
  overflow-y: scroll;
  padding: 1em;
  font-size: 80%;
`;

const Section = styled.div`
  margin-top: 20px;
`;

const TextArea = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCheck = styled(Check)`
  height: 14px;
`;

const stringifyRange = (min?: number, max?: number) => (min === max ? `${getInputValue(max)}` : `${min}-${max}`);

interface SummaryModalProps {
  saveChanges: (comment: string, name?: string) => void;
  closeModal: () => void;
}

export const SummaryModal = ({ saveChanges, closeModal }: SummaryModalProps) => {
  const { agreementType } = useAgreementsPageContext();
  const { agreementErrorMessage, setAgreementErrorMessage } = useAgreementDetailsContext();
  const { agreementTargets } = useAgreementTargets();
  const affectedAgreementTypes = targetTypeToFriendlyString(agreementTargets.targetType ?? 0).toLowerCase();

  const headers =
    agreementType === AgreementTypeEnum.Standard
      ? ['Description', 'Tier', 'Old', 'New']
      : ['Description', 'Old', 'New'];
  const headersLayout = agreementType === AgreementTypeEnum.Standard ? '2fr 1fr 1fr 1fr' : '2fr 1fr 1fr';
  const textHeaders =
    agreementType === AgreementTypeEnum.Standard
      ? ['Id', 'Action', 'Included', 'Tier', 'Text']
      : ['Id', 'Action', 'Included', 'Text'];
  const textHeadersLayout = agreementType === AgreementTypeEnum.Standard ? '1fr 1fr 1fr 1fr 4fr' : '1fr 1fr 1fr 4fr';

  const renderValueRow = useCallback(
    (name: string, item: ValueSummary<number>) => (
      <TableRow key={name + (item.tier ?? '')}>
        <TableCell>{name}</TableCell>
        {agreementType === AgreementTypeEnum.Standard && <TableCell>{item.tier}</TableCell>}
        <TableCell>{stringifyRange(item.rangeMinimum, item.rangeMaximum)}</TableCell>
        <TableCell>{getInputValue(item.value)}</TableCell>
      </TableRow>
    ),
    [agreementType]
  );

  const renderCoverageRows = useCallback(
    (
      daysCovered?: ValueSummary<number>,
      maximumDaysCovered?: ValueSummary<number>,
      quoteValidHours?: ValueSummary<number>,
      contractType?: ValueSummary<ContractType>,
      maximumProRataDays?: ValueSummary<number>
    ) => (
      <>
        {quoteValidHours !== undefined && renderValueRow('Quote valid Hours', quoteValidHours)}
        {daysCovered !== undefined && renderValueRow('Days covered', daysCovered)}
        {maximumDaysCovered !== undefined && renderValueRow('Maximum days covered', maximumDaysCovered)}
        {contractType !== undefined && (
          <TableRow>
            <TableCell>Contract type</TableCell>
            {agreementType === AgreementTypeEnum.Standard && <TableCell />}
            <TableCell>
              {contractType.rangeMinimum === contractType.rangeMaximum
                ? contractType.rangeMaximum
                : `${contractType.rangeMinimum}-${contractType.rangeMaximum}`}
            </TableCell>
            <TableCell>{contractType.value}</TableCell>
          </TableRow>
        )}
        {maximumProRataDays !== undefined && renderValueRow('Maximum pro rata days', maximumProRataDays)}
      </>
    ),
    [agreementType, renderValueRow]
  );

  const renderRatesRows = useCallback(
    (rates: RateItemSummary[]) =>
      rates.map(item => {
        const name = item.standalone ? `${item.shortName} stand-alone` : item.shortName;
        const nameAP = item.standalone ? `${item.shortName} AP` : item.shortName;
        return !item.minPremium?.value ? (
          renderValueRow(name, item)
        ) : (
          <>
            {renderValueRow(name, item)}
            {renderValueRow(nameAP, item.minPremium)}
          </>
        );
      }),
    [renderValueRow]
  );

  const renderRebatesRows = useCallback(
    (rebates: ItemSummary<number>[]) => rebates.map(item => renderValueRow(item.shortName, item)),
    [renderValueRow]
  );

  const renderTextRows = useCallback(
    (text: TextConditionSummary[]) =>
      text.map(item => (
        <TableRow key={item.termId}>
          <TableCell>{item.termId}</TableCell>
          <TableCell>{item.action}</TableCell>
          <TableCell>{item.includedOptions.some(x => x.option) ? <StyledCheck /> : null}</TableCell>
          {agreementType === AgreementTypeEnum.Standard && <TableCell>{item.tier}</TableCell>}
          <TableCell>
            <TextArea>{item.text}</TextArea>
          </TableCell>
        </TableRow>
      )),
    [agreementType]
  );

  const { areaIds, vesselIds, vesselTypeIds, isTrade } = useAgreementSelectionContext();
  const {
    viewMode,
    mutateAgreement,
    comment: globalComment,
    setComment: setGlobalComment,
    agreementName: globalAgreementName,
    setAgreementName: setGlobalAgreementName,
  } = useAgreementDetailsContext();

  const { coverage, premium, conditions } = useAgreementDataContext();

  const breachDays = coverage.daysCovered.isDirty ? coverage.daysCovered : undefined;
  const quoteValidHours = coverage.quoteValidHours.isDirty ? coverage.quoteValidHours : undefined;
  const contractType = coverage.contractType.isDirty ? coverage.contractType : undefined;
  const maximumProRataDays = coverage.maximumProRataDays.isDirty ? coverage.maximumProRataDays : undefined;
  const maximumDaysCovered = coverage.maximumDaysCovered.isDirty ? coverage.maximumDaysCovered : undefined;

  const rates = premium.defaultRates.filter(x => x.isDirty);
  const additionalRates = premium.additionalRates.filter(x => x.isDirty);
  const allRates = rates.concat(additionalRates);

  const rebates = premium.rebates.filter(x => x.isDirty);
  const knrRebates = premium.knrRebates.filter(x => x.isDirty);

  const warranties = conditions.warranties.filter(x => x.isDirty);
  const subjectivities = conditions.subjectivities.filter(x => x.isDirty);

  const [agreementName, setAgreementName] = useState(globalAgreementName);
  const [comment, setComment] = useState(globalComment);
  const [showCommentRequired, setShowCommentRequired] = useState(false);

  const isCreating = viewMode === AgreementViewMode.Create;

  const {
    data: vessels,
    isLoading: vesselsLoading,
    error: vesselsError,
  } = useVessels(
    agreementType === AgreementTypeEnum.Specific && vesselIds && vesselIds.length > 0
      ? { selectedVesselIds: vesselIds, pageSize: 1000 }
      : undefined
  );

  const {
    data: ports,
    isLoading: portsLoading,
    error: portsError,
  } = usePorts(areaIds && areaIds.length > 0 ? { selectedAreaIds: areaIds, pageSize: 1000 } : undefined);

  const { data: vesselTypes, isLoading: vesselTypesLoading } = useVesselTypes();
  const relevantVesselTypes = useMemo(
    () => vesselTypes?.filter(x => vesselTypeIds.includes(x.id)),
    [vesselTypeIds, vesselTypes]
  );

  const handleCommentInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.length > 0 && showCommentRequired) setShowCommentRequired(false);
    setComment(e.target.value);
  };
  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.length <= 50) setAgreementErrorMessage('');
    else setAgreementErrorMessage('Trade Agreement Name must be less than 50 characters.');
    setAgreementName(e.target.value);
    setGlobalAgreementName(e.target.value);
  };

  const renderTarget = () =>
    agreementType === AgreementTypeEnum.Standard ? (
      <Target>
        {ports && areaIds.length > 0 ? (
          <>
            <TargetHeader>{`Covered Areas (${ports.items.length})`}</TargetHeader>
            {ports.items.map(p => (p.areaType === 'Port' ? p.portName : p.oceanAreaName)).join(', ')}
          </>
        ) : (
          <TargetHeader error>No ports selected!</TargetHeader>
        )}

        {relevantVesselTypes?.some(x => x) && (
          <>
            <TargetHeader>{`Vessel Types (${relevantVesselTypes.length})`}</TargetHeader>
            {relevantVesselTypes.map(x => x.name).join(', ')}
          </>
        )}
      </Target>
    ) : (
      <Target>
        <>
          {vessels && vesselIds.length > 0 ? (
            <>
              <TargetHeader>Fleet</TargetHeader>
              {[...new Set(vessels.items.map(f => f.fleetName))].join(', ')}
              <TargetHeader>{`Vessels (${vessels?.items.length})`}</TargetHeader>
              {vessels?.items.map(v => v.name).join(', ')}
            </>
          ) : (
            <TargetHeader error>No vessels selected</TargetHeader>
          )}

          {ports && areaIds.length > 0 ? (
            <>
              <TargetHeader>{`Excluded Areas (${ports.items.length})`}</TargetHeader>
              {ports.items.map(p => (p.areaType === 'Port' ? p.portName : p.oceanAreaName)).join(', ')}
            </>
          ) : (
            <TargetHeader error>No areas selected</TargetHeader>
          )}
        </>
      </Target>
    );

  const submit = () => {
    if (!comment || comment.length < 1) {
      setShowCommentRequired(true);
    }

    if (agreementName?.length > 50) {
      setAgreementErrorMessage('Trade Agreement Name must be less than 50 characters.');
    }

    if (!comment || comment.length < 1 || agreementName?.length > 50) return;

    saveChanges(comment, agreementName);
    closeModal();
  };

  const discard = () => {
    mutateAgreement();
    setAgreementErrorMessage('');
    closeModal();
  };

  const keepEditing = () => {
    setGlobalAgreementName(globalAgreementName);
    setGlobalComment(comment);
    closeModal();
  };

  const modalOptions = {
    title: 'Apply Changes?',
    size: 'medium',
  };

  return (
    <ModalLayout closeModal={closeModal} options={modalOptions}>
      <Container>
        <Column gap="2rem">
          <WarnOverwriteExistingAgreements />

          {isTrade && (
            <TextField
              name="name"
              rows={2}
              maxLength={50}
              label="Trade Agreement Name"
              value={agreementName}
              onChange={handleNameInputChange}
              placeholder="Give the trade agreement a custom name."
            />
          )}
          {agreementErrorMessage && <StyledError>{agreementErrorMessage}</StyledError>}
          <TextField
            name="comment"
            rows={2}
            label="Comment"
            value={comment}
            onChange={handleCommentInputChange}
            placeholder="What are the changes regarding?"
          />
          {showCommentRequired && <StyledError>A comment is required.</StyledError>}
          <Box header={`Changes to ${affectedAgreementTypes} agreement(s).`} shaded={false}>
            <Content>
              {!vesselsLoading && !portsLoading && !vesselTypesLoading ? (
                renderTarget()
              ) : (
                <NoDataRow
                  loadStatus={{ loading: vesselsLoading || portsLoading, success: !vesselsError || !portsError }}
                />
              )}

              <Changes>
                {(breachDays !== undefined ||
                  maximumDaysCovered !== undefined ||
                  contractType !== undefined ||
                  quoteValidHours !== undefined ||
                  maximumProRataDays !== undefined) && (
                    <Section>
                      <Box header="Coverage">
                        <Table columns={headers.length} striped layout={headersLayout}>
                          {renderTableHeaders(headers)}
                          {renderCoverageRows(breachDays, maximumDaysCovered, quoteValidHours, contractType, maximumProRataDays)}
                        </Table>
                      </Box>
                    </Section>
                )}
                {allRates.length > 0 && (
                  <Section>
                    <Box header="Rates">
                      <Table columns={headers.length} striped layout={headersLayout}>
                        {renderTableHeaders(headers)}
                        {renderRatesRows(allRates)}
                      </Table>
                    </Box>
                  </Section>
                )}
                {rebates.length > 0 && (
                  <Section>
                    <Box header="Rebates">
                      <Table columns={headers.length} striped layout={headersLayout}>
                        {renderTableHeaders(headers)}
                        {renderRebatesRows(rebates)}
                      </Table>
                    </Box>
                  </Section>
                )}
                {knrRebates.length > 0 && (
                  <Section>
                    <Box header="K&R Rebates">
                      <Table columns={headers.length} striped layout={headersLayout}>
                        {renderTableHeaders(headers)}
                        {renderRebatesRows(knrRebates)}
                      </Table>
                    </Box>
                  </Section>
                )}
                {warranties.length > 0 && (
                  <Section>
                    <Box header="Warranties">
                      <Table columns={textHeaders.length} striped layout={textHeadersLayout}>
                        {renderTableHeaders(textHeaders)}
                        {renderTextRows(warranties)}
                      </Table>
                    </Box>
                  </Section>
                )}
                {subjectivities.length > 0 && (
                  <Section>
                    <Box header="Subjectivities">
                      <Table columns={textHeaders.length} striped layout={textHeadersLayout}>
                        {renderTableHeaders(textHeaders)}
                        {renderTextRows(subjectivities)}
                      </Table>
                    </Box>
                  </Section>
                )}
              </Changes>
            </Content>
          </Box>

          <ButtonGroup justify="flex-end">
            <Button onClick={keepEditing}>Keep Editing</Button>

            {!isCreating ? (
              <Button onClick={discard} background={theme.ultraLightGray}>
                Discard Changes
              </Button>
            ) : null}
            <Button onClick={() => submit()} background={theme.green}>
              Apply Changes
            </Button>
          </ButtonGroup>
          {showCommentRequired && <StyledError>A comment is required</StyledError>}
        </Column>
      </Container>
    </ModalLayout>
  );
};
