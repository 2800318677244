import {
  useEffect, useMemo, useState
} from 'react';
import { useAgreementTargets } from '@/components/pages/agreements/useAgreementTargets';
import { useAgreementsSummaryAlternatives } from '@/services/agreementsService';
import { WarWeb } from '@/war';

// Saving changes triggers revalidation, this hook is to avoid returning undefined while revalidating
const useAlternatives = (targets: WarWeb.AgreementTargets) => {
  const { data, isLoading, isValidating } = useAgreementsSummaryAlternatives({ ...targets, daysCoveredGroup: undefined, minimumSumInsured: undefined });
  const [alternatives, setAlternatives] = useState<WarWeb.AgreementAlternativesSummary[] | undefined>(data);

  useEffect(() => {
    if (!isLoading && !isValidating) {
      setAlternatives(data);
    }
  }, [data, isLoading, isValidating]);

  return alternatives;
};

export const useTabNames = () => {
  const { agreementTargets } = useAgreementTargets();
  const alternatives = useAlternatives(agreementTargets);

  const daysCoveredTabs = useMemo(() => alternatives?.flatMap(x => x.daysCovered).map(x => `Days Covered - ${x} days`) ?? [], [alternatives]);
  const tabNames = useMemo(() => [
    ...daysCoveredTabs,
    'Warranties',
    'Subjectivities'
  ], [daysCoveredTabs]);

  if (!alternatives) {
    return [];
  }

  return tabNames;
};
