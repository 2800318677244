import {
  Dispatch, SetStateAction, useState
} from 'react';
import { useHistory } from 'react-router';
import { isBroker, isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { NameGroup, Tooltip } from '@/components/shared/Table/core/Components';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { useTermsConditions } from '@/services/termsService';
import { addOrRemoveArray } from '@/utility/stateArrayHelpers';
import { WarWeb } from '@/war';
import { TargetType } from '@/models/TargetType';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { Globals } from '@/utility/globals';
import { useAgreementsOverviewContext } from '../agreementsOverview/AgreementsOverviewContext';
import { useFilterableTableHeadersContext } from '../defaultTermsConditions/FilterableTableHeadersContext';
import { renderSharedSpecificTermsTableCells } from './SharedTableCells';
import { useTermsConditionsPageContext } from './TermsConditionsPageContext';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { OverflowItemsRow } from '../defaultTermsConditions/OverflowItemsRow';

interface TermsConditionsRowProps {
  terms: WarWeb.SpecificTcMatch | WarWeb.BrokerTcMatch;
  focusedId?: number;
  setFocusedId: Dispatch<SetStateAction<number | undefined>>;
}
const TermsConditionsRow = ({ terms, focusedId, setFocusedId }: TermsConditionsRowProps) => {
  const { agreementEntries, setAgreementEntries } = useAgreementsOverviewContext();
  const { setAgreementSummaryTarget } = useAgreementsPageContext();
  const { setMainActionClicked, isClaimsPenaltyBased, isNameColumnVisible } = useTermsConditionsPageContext();
  const history = useHistory();
  const termsExistsInSelection = agreementEntries.map(x => x.agreementId).includes(terms.agreementId);

  const currentEntry = {
    agreementId: terms.agreementId,
    agreementIds: terms.agreementIds,
    fleetId: terms.fleetId,
    areaIds: terms.coveredAreaIds,
    vesselIds: terms.vesselIds,
    level1: terms.level1,
    level2: terms.level2,
    type: terms.coveredAreaType,
  };

  const handleCheckboxChange = () => {
    addOrRemoveArray(
      agreementEntries,
      setAgreementEntries,
      s => s.agreementIds.some((a: number) => terms.agreementIds.some(t => t === a)),
      currentEntry
    );
  };

  const handleClick = () => {
    let targetType = currentEntry.type === 'Trade' ? TargetType.Trade : TargetType.VesselSpecific;
    if (!isInternalUser()) targetType = TargetType.Unspecified;
    setAgreementSummaryTarget({
      agreementIds: currentEntry.agreementIds,
      targetType,
      fleetId: currentEntry.fleetId,
      vesselIds: isBroker() ? currentEntry.vesselIds : undefined
    });
    setAgreementEntries([currentEntry]);
    setMainActionClicked(true);
    setTimeout(() => {
      history.push(Routes.termsSpecificDetails);
    }, 500);
  };

  return (
    <TableRow
      onClick={handleClick}
      hasFocus={focusedId === terms.agreementId}
      onHover={() => setFocusedId(undefined)}
      hover
    >
      <TableCell
        slim
        onClick={e => e.stopPropagation()}
        tabIndex={isBroker() ? 0 : undefined}
        onKeyDown={e => (e.key === 'Enter' ? handleClick() : undefined)}
        onFocus={() => setFocusedId(terms.agreementId)}
        onBlur={() => setFocusedId(undefined)}
      >
        {isInternalUser() && terms.coveredAreaType !== 'Trade' && (
          <Checkbox name={`${terms.agreementId}`} checked={termsExistsInSelection} onChange={handleCheckboxChange} />
        )}
      </TableCell>
      {isInternalUser() && <TableCell />}
      <TableCell>{terms.level3}</TableCell>
      {isNameColumnVisible && (
        <TableCell>
          <Tooltip name={terms.agreementName}>{terms.agreementName}</Tooltip>
        </TableCell>
      )}
      {isInternalUser() && (
        <>
          <TableCell>
            <Tooltip name={terms.client}>{terms.client}</Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip name={terms.fleet}>{terms.fleet}</Tooltip>
          </TableCell>
        </>
      )}
      <TableCell>
        <NameGroup values={terms.vessels} />
      </TableCell>
      <TableCell>
        <NameGroup values={terms.vesselTypes} />
      </TableCell>
      {isInternalUser() && <TableCell>{(terms as WarWeb.SpecificTcMatch).tier}</TableCell>}
      {renderSharedSpecificTermsTableCells(terms, isBroker() && isClaimsPenaltyBased)}
    </TableRow>
  );
};

interface TermsConditionsProps {
  level1: string;
  level2: string;
}
export const TermsConditions = ({ level1, level2 }: TermsConditionsProps) => {
  const { fleetId } = useTermsConditionsPageContext();
  const { filters: baseParams } = useFilterableTableHeadersContext();
  const orderBy = baseParams?.orderBy ? baseParams.orderBy : 'Level3';
  const roleParams = isBroker() ? { ...baseParams, orderBy, fleetId } : { ...baseParams, orderBy };
  const params = {
    ...roleParams,
    selectedLevel1: [level1],
    selectedLevel2: [level2],
    pageSize: Globals.AgreementsOverviewGroupSize,
  };
  const { data } = useTermsConditions(params, JSON.stringify(params));
  const [focusedId, setFocusedId] = useState<number>();

  return (
    <>
      {data?.items.map((terms: WarWeb.SpecificTcMatch | WarWeb.BrokerTcMatch) => (
        <TermsConditionsRow key={terms.agreementId} focusedId={focusedId} setFocusedId={setFocusedId} terms={terms} />
      ))}
      <OverflowItemsRow paging={data?.pagingMetadata} />
    </>
  );
};
