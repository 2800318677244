import { NoDataRow } from '@/components/shared/Table/Table';
import { useAgreementTargets } from '@/components/pages/agreements/useAgreementTargets';
import {
  ContractType, CoverageSummary, ValueSummary
} from '@/models/Agreement';
import { useAgreements } from '@/services/agreementsService';
import { useEffect } from 'react';
import { TargetType } from '@/models/TargetType';
import { useQuery } from '@/hooks/useQuery';
import { setAgreementDetailsParams } from '@/utility/sessionStore';
import { isBroker } from '@/components/routing/roles';
import { ChildrenProps } from '@/models/utils/ChildrenProps';
import { Redirect } from 'react-router';
import { Routes } from '@/components/routing/routes';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementDataContext } from './AgreementDataContext';
import { AgreementViewMode, useAgreementDetailsContext } from './AgreementDetailsContext';
import { useAgreementSelectionContext } from './AgreementSelectionContext';

export const AgreementDetailsInitializer = ({ children }: ChildrenProps) => {
  const { agreementType, createAgreementParams, agreementSummaryTarget } = useAgreementsPageContext();
  const { setAreaIds, setFleetIds, setVesselIds, setVesselTypeIds, setAreaIdsTimeExceptions, setTargetType } = useAgreementSelectionContext();
  const { viewMode, setTargetsSummary, setMutateAgreement, setComment, setAgreementName } = useAgreementDetailsContext();
  const { setConditions, setCoverage, setPremium } = useAgreementDataContext();
  const { agreementTargets } = useAgreementTargets();

  const query = useQuery();
  const queryAreaIds = query.get('areaIds');
  const queryTargetType = query.get('targetType');

  const agreementTargetsInitialized = agreementTargets.agreementIds?.some(x => x) || viewMode === AgreementViewMode.Create ? agreementTargets : undefined;
  const agreementTargetsRateGuideSupport = queryAreaIds && queryTargetType ?
    { areaIds: [parseInt(queryAreaIds)], targetType: parseInt(queryTargetType) } : agreementTargetsInitialized;

  const hasTargets = !!agreementTargets.targetType || viewMode === AgreementViewMode.Create;
  const { data, mutate, error, isValidating: isLoading } = useAgreements(agreementTargetsRateGuideSupport, agreementType, 'detailsInitializer');

  const setupData = () => {
    if (data) {
      setComment('');
      setTargetType(viewMode === AgreementViewMode.Create ? TargetType.VesselSpecific : agreementSummaryTarget.targetType);

      const dataDeepCopy = structuredClone(data);

      const coverageBreachDays = dataDeepCopy.coverage.daysCovered;
      const coverageMaxDays = dataDeepCopy.coverage.maximumDaysCovered;
      const coverageQuoteHours = dataDeepCopy.coverage.quoteValidHours;
      const coverageFromArrayToObject: CoverageSummary = {
        daysCovered: coverageBreachDays,
        quoteValidHours: coverageQuoteHours,
        maximumDaysCovered: coverageMaxDays,
        contractType: dataDeepCopy.coverage.contractType as ValueSummary<ContractType>,
        maximumProRataDays: dataDeepCopy.coverage.maximumProRataDays as ValueSummary<number>
      };

      setAgreementName(dataDeepCopy.header?.name);
      setCoverage(coverageFromArrayToObject);
      setPremium({
        defaultRates: dataDeepCopy.defaultRates,
        additionalRates: dataDeepCopy.additionalRates,
        rebates: dataDeepCopy.rebates,
        knrRebates: dataDeepCopy.knrRebates
      });
      setConditions({
        warranties: dataDeepCopy.warranties,
        subjectivities: dataDeepCopy.subjectivities
      });

      setTargetsSummary(dataDeepCopy.targets);

      setAreaIds(viewMode === AgreementViewMode.Create ? createAgreementParams.areaIds : dataDeepCopy.targets.areas.map(x => x.areaId));
      setFleetIds([...new Set(dataDeepCopy.targets.vessels.map(x => x.fleetId))]);
      setVesselIds(dataDeepCopy.targets.vessels.map(x => x.vesselId));
      setVesselTypeIds(dataDeepCopy.targets.vesselTypes.map(x => x.vesselTypeId));
      setAreaIdsTimeExceptions(dataDeepCopy.targets.areas.filter(x => x.areaType === 'NonExcluded').map(x => x.areaId));

      // If data was fetched and contains agreementIds, store selected ids in session store
      if (dataDeepCopy.targets.agreementIds.length > 0 &&
        (agreementSummaryTarget.targetType || (isBroker() && agreementSummaryTarget.targetType === TargetType.Unspecified))) {
        setAgreementDetailsParams({
          fleetIds: [...new Set(dataDeepCopy.targets.vessels.map(x => x.fleetId))],
          agreementIds: dataDeepCopy.targets.agreementIds.map(x => x.agreementId),
          targetType: agreementSummaryTarget.targetType,
          daysCoveredGroup: agreementSummaryTarget.daysCoveredGroup,
          minimumSumInsured: agreementSummaryTarget.minimumSumInsured,
        });
      }
    }
  };

  const resetData = () => {
    void mutate();
    setupData();
  };

  useEffect(() => {
    setupData();
    setMutateAgreement(() => resetData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // No targets in this session, redirect to terms
  if (!hasTargets) return <Redirect to={Routes.termsDefault} />;

  return data && !isLoading ? (
    <>
      {children}
    </>
  ) :
    <NoDataRow loadStatus={{ loading: isLoading, success: data && !error }} />;
};
