import { ChildrenProps } from '@/models/utils/ChildrenProps';
import React, {
  createContext, useContext, useEffect, useMemo, useState
} from 'react';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';

export interface AgreementEntry {
  agreementId: number;
  agreementIds: number[];
  areaIds: number[];
  vesselIds?: number[];
  vesselTypeIds?: number[];
  fleetId?: number;
  level1: string;
  level2: string;
  type: string;
  daysCovered?: number;
  // checked?: boolean; TODO: Is this a better idea?
}

const AgreementsOverviewContext = createContext<IAgreementsOverviewContext | undefined>(undefined);

interface IAgreementsOverviewContext {
  agreementEntries: AgreementEntry[];
  setAgreementEntries: React.Dispatch<React.SetStateAction<AgreementEntry[]>>;
}

const AgreementsOverviewProvider = ({ children }: ChildrenProps) => {
  const [agreementEntries, setAgreementEntries] = useState<AgreementEntry[]>([]);

  const { setIsBulkEdit } = useAgreementsPageContext();

  useEffect(() => {
    setIsBulkEdit(agreementEntries.length > 1);
  }, [agreementEntries]);

  const value = useMemo(
    () => (
      { agreementEntries, setAgreementEntries }),
    [agreementEntries]
  );

  return (
    <AgreementsOverviewContext.Provider value={value}>
      {children}
    </AgreementsOverviewContext.Provider>
  );
};

const useAgreementsOverviewContext = () => {
  const context = useContext(AgreementsOverviewContext);

  if (context === undefined) {
    throw new Error('useAgreementsOverviewContext must be used within a AgreementsOverviewProvider');
  }
  return context;
};

export { AgreementsOverviewProvider, useAgreementsOverviewContext };
